<template>
    <div>
        <div>
            <el-tabs v-model="activeName" @tab-click="handleClick" style="padding-left: 10px">

                <el-tab-pane label="图表" name="pie">
                    <el-button size="mini" type="primary" class="btnSearch" @click="download()">下载数据</el-button>
                    <el-select size="small" class="btnSearch"
                               v-model="queryForm.equipNo" @change="changeChart()"
                               filterable clearable placeholder="请选择设备">
                        <el-option
                            v-for="item in queryEquipList"
                            :key="item.id" :label="item.equipNo" :value="item.equipNo">
                        </el-option>
                    </el-select>
                    <!-- <el-checkbox style="margin: 10px 10px;float:right" v-model="checked" label="1">自动刷新</el-checkbox> -->
                </el-tab-pane>
                <!--表格全展示-->
                <el-tab-pane label="数据" name="data">
                    <b class="fontLabel">今日铜粒子编码读取失败数量</b>:  {{failNumber}}
                    <el-button size="mini" type="primary" class="btnSearch" @click="download()">下载数据</el-button>
                    <el-select size="small" class="btnSearch"
                               v-model="queryForm.equipNo" @change="changeTable()"
                               filterable clearable placeholder="请选择设备">
                        <el-option
                            v-for="item in queryEquipList"
                            :key="item.id" :label="item.equipNo" :value="item.equipNo">
                        </el-option>
                    </el-select>

                    <!--内容按是否合格展示-->
                    <el-select size="small" class="btnSearch" ref="selectValue1"
                               v-model="selectValue.flag"
                                @change="changeTable1()"
                               filterable clearable placeholder="是否合格">
                        <el-option
                            v-for="item in options1"
                            :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    <!-- <el-checkbox style="margin: 10px 10px;float:right" v-model="checked" label="1">自动刷新</el-checkbox> -->

                    <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" :height="tableHeight">

                        <el-table-column prop="deviceNo" label="设备名" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="productCode" label="产品编码" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="datetime" label="测量时间" :formatter="dateFormat" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="type" label="类型" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="aArea" label="A面大小" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="bArea" label="B面大小" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="aIsOk" label="A面是否合格" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="bIsOk" label="B面是否合格" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="aImageName" label="A图名" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="bImageName" label="B图名" show-overflow-tooltip></el-table-column>
                    </el-table>
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="currentPage"
                        :page-sizes="[20, 50, 100, 200]"
                        :page-size="20"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="Number(totalCount)">
                    </el-pagination>
                </el-tab-pane>
            </el-tabs>
        </div>

        <div id="pie"></div>

    </div>
</template>

<script>
import echarts from "echarts";
import moment from 'moment';
import {fGetApiURL} from "@/utils/utilsset";
import {sanyouAxios} from "@/api";
export default {
    name: "copperrtdata",

    data() {
        return {
            currentUserId:"",
            //表格高度
            tableHeight:500,
            //表格数据
            tableData: [],

            checked: false,
            activeName: 'pie',
            isShow: 'false',
            //选择设备
            queryEquipList:[],
            queryForm:{
                equipNo:"",
            },
            selectValue: {
                flag:"",
            },
            options1: [{
                value:1,
                label: '合格'
            }, {
                value:0,
                label: '不合格'
            }],
            //分页
            currentPage:1,
            pageSize: 20,
            totalCount:0,
            //日期
            startTime:"",
            endTime:"",
            failNumber:0
        };
    },
    mounted(){
        // if(this.queryForm.equipNo != ''){
        //   this.drawPie();
        // }
        this.$nextTick(() => {
            this.tableHeight = window.innerHeight - this.$refs.multipleTable.$el['offsetTop'] - 300;
            window.onresize = () => {
                this.tableHeight = window.innerHeight - this.$refs.multipleTable.$el['offsetTop'] - 300;
            };
        });
    },
    methods: {
        //点击标签事件
        handleClick(tab, event) {
            if (tab.name === "pie") {
                this.drawPie();
            } else {
                this.showData();
                this.showFailNumber();
            }
        },
        //绘制图表
        drawPie() {
            //基于准备好的dom，初始化echarts实例
            //this.isShow = false;
            document.getElementById("pie").style="display: block;width: 100%; height: 500px;margin-top:30px"
            let myChart = this.$echarts.init(document.getElementById("pie"));

            let data = [];
            sanyouAxios({
                headers:{
                    userId:this.currentUserId
                },
                method: 'POST',
                url: '/copper/copperPieRtData',
                data: {
                    deviceNo:this.queryForm.equipNo,
                    userId:this.currentUserId,
                    startTime:this.startTime,
                    endTime:this.endTime
                }
            })
                .then(res=>{
                    if(res.data.status === 200){

                        for(let i=0;i<res.data.data.length;i++){
                            data.push({value:res.data.data[i].value,name:res.data.data[i].name});
                        }
                        myChart.setOption({
                            title: {
                                text: "检测数据实时统计", //主标题
                                x: "center", //x轴方向对齐方式
                            },
                            tooltip: {
                                trigger: "item",
                                formatter: "{b}: {c} ({d}%)",
                            },
                            legend: {
                                orient: "vertical",
                                bottom: "bottom",
                            },
                            series: [
                                {
                                    type: "pie",
                                    radius: "80%",
                                    center: ["50%", "50%"],
                                    data: data,
                                    itemStyle: {
                                        emphasis: {
                                            shadowBlur: 10,
                                            shadowOffsetX: 0,
                                            shadowColor: "rgba(0, 0, 0, 0.5)",
                                        },
                                        normal: {
                                            color: function(params) {
                                                //自定义颜色
                                                let colorList = [
                                                    '#4CAE85', '#FF6356'
                                                ];
                                                return colorList[params.dataIndex]
                                            }
                                        }
                                    },
                                },
                            ],
                        });
                    }else{
                        this.$message({
                            duration: 2000,
                            message: res.data.msg,
                            type: 'warning'
                        });
                    }
                });

        },
        //显示数据
        showData() {
            document.getElementById("pie").style="display: none"
            // this.isShow = true;
            this.loadData(1,20);
        },
        //显示读取失败数据
        showFailNumber(){
            sanyouAxios({
                headers:{userId:this.currentUserId},
                method: 'POST',
                url:"/copper/getFailNumber?equipNo="+this.queryForm.equipNo})
                .then(res=>{
                    if(res.data.status === 200){
                        this.failNumber = res.data.data;
                    }else{
                        this.$message({
                            type: 'warning',
                            duration: 2000,
                            message: '获取失败!'
                        });
                    }
                });
        } ,
        //获取用户所拥有权限的设备信息
        loadEquipList(){
            sanyouAxios({method: 'GET',
                url:"/equipment/getUserEquip?userId=" + this.currentUserId+"&&equipType=1"
            })
                .then(res=>{
                    if(res.data.status === 200){
                        this.queryEquipList = res.data.data;
                        if(this.queryEquipList.length > 0){
                            this.queryForm.equipNo = this.queryEquipList[0].equipNo;
                            this.drawPie();
                        }
                    }else{
                        this.$message({
                            type: 'warning',
                            duration: 2000,
                            message: '获取设备信息失败!'
                        });
                    }
                });
        },
        //加载检测数据
        loadData: function(page, pageSize){
            let _this = this;

            if(page == null || isNaN(page))
                page = 1;
            if(pageSize == null || isNaN(pageSize))
                pageSize = 20;

            sanyouAxios({
                headers:{
                    userId:this.currentUserId
                },
                method: 'POST',
                url:'/copper/copperRtData?page=' + page + '&pageSize=' + pageSize,
                data:{
                    deviceNo:this.queryForm.equipNo,
                    userId:this.currentUserId,
                    flag:this.selectValue.flag,
                }
            })
                .then(res => {
                    if(res.data.status === 200){
                        let data = res.data.data;
                        _this.tableData = data.rows;
                        _this.totalCount = data.records;
                    }else{
                        this.$message({
                            type: 'warning',
                            duration: 2000,
                            message: '没有权限查看!'
                        });
                    }
                });
        },
        //加载是否合格的数据
        loadData1: function(page, pageSize){
            let _this = this;

            if(page == null || isNaN(page))
                page = 1;
            if(pageSize == null || isNaN(pageSize))
                pageSize = 20;

            sanyouAxios({
                headers:{
                    userId:this.currentUserId
                },
                method: 'POST',
                url:'/copper/copperRtData?page=' + page + '&pageSize=' + pageSize ,
                data:{
                    deviceNo:this.queryForm.equipNo,
                    userId:this.currentUserId,
                    flag:this.selectValue.flag,
                }
            })
                .then(res => {
                    if(res.data.status === 200){
                        let data = res.data.data;
                        _this.tableData = data.rows;
                        _this.totalCount = data.records;
                    }else{
                        this.$message({
                            type: 'warning',
                            duration: 2000,
                            message: '没有权限查看!'
                        });
                    }
                });
        },

        //每页显示数据量变更
        handleSizeChange: function (val) {
            this.pageSize = val;
            let currentPage = this.currentPage;
            this.loadData(currentPage,val);
        },
        //页码变更
        handleCurrentChange: function (val) {
            this.currentPage = val;
            let pageSize = this.pageSize;
            this.loadData(val,pageSize);
        },
        //日期格式化
        dateFormat:function(row,column){
            let date = row[column.property];
            if(date === undefined){
                return ''
            }
            return moment(date).format("YYYY-MM-DD HH:mm:ss")
        },

        //下载数据
        download(){
            /*let startTime = this.startTime.replace(/-/g,'/');
            let endTime = this.endTime.replace(/-/g,'/');*/
            window.open(fGetApiURL()+"/copper/copperRtDownload" +
                "?equipNo=" + this.queryForm.equipNo+"&flag="+this.selectValue.flag);
        },

        changeChart(){
            this.drawPie();
        },

        changeTable(){
            this.loadData(1,20);
            this.showFailNumber();
        },
        changeTable1(){
            this.loadData1(1,20,);
        }

    },
    created() {
        this.currentUserId = localStorage.getItem('id');

        const nowDate = new Date();
        const date = {
            year: nowDate.getFullYear(),
            month: nowDate.getMonth() + 1,
            date: nowDate.getDate(),
        }
        const newmonth = date.month>=10?date.month:'0'+date.month;
        const day = date.date>=10?date.date:'0'+date.date;
        this.startTime = date.year + "-" + newmonth + '-' + day;
        this.endTime = date.year + "-" + newmonth + '-' + day;

        this.loadEquipList();
        this.selectValue.flag=0;
    }

};
</script>
<style scoped>
.container {
    height: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgb(245, 245, 246);;
}
.container-left {
    margin-left: 10px;
    height: 56px;
    line-height: 56px;
}
.space {
    margin: 5px;
}
.btnSearch {
    margin: 5px 10px;
    float: right;
}
</style>
